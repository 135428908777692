import React from "react";

const SingleProjectHeader = ({ singleArticles }) => {
    return (
        <section className="flex flex-col lg:flex-row justify-between items-start gap-5">
            <div className="flex flex-col">
                <div className="flex flex-wrap gap-x-3">
                    {singleArticles.fields.tags.map((tag, i) => (
                        <p
                            key={i}
                            className="text-gray-500 rounded-full pr-2 py-1 outfit-medium text-[14px] uppercase tracking-wider"
                        >
                            {tag}
                        </p>
                    ))}
                </div>
                <p className="outfit-medium text-[24px] md:text-[39px] lg:text-[50px] w-full lg:w-4/5">
                    {singleArticles.fields.title}
                </p>
            </div>
            <p
                className="w-full lg:w-1/2 outfit text-[1rem] lg:text-[1.1rem] text-gray-700 leading-7"
            >
                {singleArticles.fields.outline}
            </p>
        </section>
    );
};

export default SingleProjectHeader;
