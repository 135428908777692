import React, { useEffect, useState } from "react";
import ReactLenis from "lenis/react";
import client from "../../client";
import { useParams } from "react-router-dom";

import BadRequest from "../maintenance/400Page";
import AnotherWork from "./component/AnotherWork";
import LoadingPage from "../../components/loading/loadingPage";
import PageNotFound from "../maintenance/404Page";
import FloatingBar from "../../components/floating bar/FloatingBar";
import SingleWorkHeader from "./component/SingleWorkHeader";
import SingleWorkDetails from "./component/SingleWorkDetails";
import SingleWorkStory from "./component/SingleWorkStory";
import SingleWorkGainedExpertise from "./component/SingleWorkGainedExpertise";
import SingleWorkSkill from "./component/SingleWorkSkill";

export function withRouter(Children) {
    return ({ match, ...props }) => {
        const updatedMatch = { params: useParams(), ...match };
        return <Children {...props} match={updatedMatch} />;
    };
}

function SingleWork({ match }) {
    const [singleArticles, setSingleArticles] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "work",
                    "fields.slug": match?.params.slug,
                });
                setSingleArticles(response.items[0]);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };
        fetchData();
    }, [match?.params.slug]);

    if (error) return <BadRequest />;

    if (loading) return <LoadingPage />;

    if (!singleArticles) return <PageNotFound />;
    return (
        <ReactLenis root>
            <FloatingBar />
            <div className="flex flex-col gap-32 xl:gap-44 my-44 px-3 sm:px-10 lg:px-20 w-full xl:w-[1280px] mx-auto">
                <div className="flex flex-col lg:flex-row justify-between gap-10 lg:gap-32">
                    <SingleWorkHeader singleArticles={singleArticles} />
                    <SingleWorkDetails singleArticles={singleArticles} />
                </div>
                <div className="flex flex-col gap-32">
                    <SingleWorkGainedExpertise
                        singleArticles={singleArticles}
                    />
                    <SingleWorkStory singleArticles={singleArticles} />
                    <SingleWorkSkill singleArticles={singleArticles} />
                </div>
                <AnotherWork anotherWork={singleArticles.fields.slug} />
            </div>
        </ReactLenis>
    );
}

export default withRouter(SingleWork);
