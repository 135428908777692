import React from "react";

const SingleProjectHeader = ({ singleArticles }) => {
    return (
        <div className="flex flex-col gap-14">
            <section className="flex flex-col items-center text-center gap-5">
                <p
                    data-aos="fade-up"
                    className="outfit-medium text-[24px] md:text-[39px] lg:text-[56px]"
                >
                    {singleArticles.fields.title}
                </p>
                <p
                    data-aos="fade-up"
                    className="w-full lg:w-[75%] outfit text-[1rem] lg:text-[1.1rem] text-gray-600 leading-7"
                >
                    {singleArticles.fields.outline}
                </p>
            </section>
            <section className="flex justify-center px-5 md:px-10">
                {(singleArticles.fields.device === "Web" ||
                    singleArticles.fields.device === "Mobile") &&
                    singleArticles.fields.headerMockup && (
                        <img
                            src={`https:${singleArticles.fields.headerMockup.fields.file.url}`}
                            alt=""
                            data-aos="fade-up"
                            className={`w-full ${
                                singleArticles.fields.device === "Web"
                                    ? "lg:w-full"
                                    : "lg:w-1/2"
                            } h-full ${
                                singleArticles.fields.device === "Web"
                                    ? "rounded-[20px]"
                                    : ""
                            }`}
                        />
                    )}
            </section>
        </div>
    );
};

export default SingleProjectHeader;
