import React, { useEffect, useState } from "react";
import client from "../../../client";
import ToastMessage from "../../../components/toast/ToastMessage";

function AlsoInteristing({ alsoInteristing }) {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "uniqueCode",
                });
                setArticles(
                    response.items.sort((a, b) =>
                        new Date(a.fields.date) > new Date(b.fields.date)
                            ? -1
                            : 1
                    )
                );
            } catch (error) {
                setError(error);
            }
        };

        fetchArticles();
    }, []);

    if (error)
        return (
            <div className="flex justify-center">
                <ToastMessage />
            </div>
        );

    return (
        <>
            <section  data-aos="fade-up" data-aos-delay="100" className="flex flex-col gap-2">
                <p className="outfit-medium text-violet-500 capitalize text-[1.25rem] lg:text-[1.1rem]">
                    Varied Topics
                </p>
                {articles.map((article, i) =>
                    article.fields.title !== alsoInteristing ? (
                        <a
                            href={`/insight/${article.fields.slug}`}
                            key={i}
                            className="group flex flex-col gap-1 outline-none"
                        >
                            <span className="outfit-medium text-slate-900 group-hover:text-violet-500 text-[1.1rem] duration-100">
                                {article.fields.title}
                            </span>
                            <p className="outfit text-slate-600 w-full md:w-1/2 lg:w-full">
                                {article.fields.description}
                            </p>
                        </a>
                    ) : null
                )}
            </section>
        </>
    );
}

export default AlsoInteristing;
