import React, { useEffect, useState } from "react";
import "lazysizes";
import ReactLenis from "lenis/react";
import client from "../../../client";
import { Link } from "react-router-dom";

import FloatingBar from "../../../components/floating bar/FloatingBar";
import BadRequest from "../../maintenance/400Page";
import SEO from "../../../components/seo/SEO";
import LoadingPage from "../../../components/loading/loadingPage";

function Project() {
    const [articles, setArticles] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.lazySizes.init();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "frontend",
                });
                setArticles(
                    response.items.sort(
                        (a, b) =>
                            new Date(b.fields.date) - new Date(a.fields.date)
                    )
                );
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    if (isLoading) return <LoadingPage />;

    if (error) return <BadRequest />;

    return (
        <ReactLenis root>
            <SEO
                title="Bring ideas to life with sleek web development and dynamic, engaging interfaces."
                keywords="Cahya, Cahya Putra, Cahya Putra Ugira, Project, Project Collection, Mini Project"
                author="Cahya Putra Ugira"
                url="https://rafaelcahya.space/project"
            />
            <FloatingBar />
            <div className="flex flex-col gap-20 md:gap-32 pt-32 xs:pt-40 md:pt-44 pb-56 px-3 sm:px-10 lg:px-20 2xl:px-40">
                <div
                    data-aos="fade-right"
                    className="flex flex-col gap-2 w-full lg:w-5/6"
                >
                    <p className="outfit text-[1.25rem] md:text-[1.5rem]">
                        My Projects
                    </p>
                    <h1 className="header3xl outfit-semibold text-slate-900 text-[2.5rem] xs:text-[3rem] md:text-[4.75rem] xl:text-[6rem] leading-snug md:leading-[6.5rem]">
                        Turning concepts into sleek,{" "}
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-amber-400 to-purple-400">
                            functional web designs
                        </span>
                    </h1>
                </div>
                <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-14 mx-auto">
                    {articles.map((article) => (
                        <Link
                            to={`/frontend/${article.fields.slug}`}
                            key={article.sys.id}
                            className="outline-none rounded-xl 2xl:w-[420px]"
                        >
                            {article.fields.thumbnailImage && (
                                <div className="group flex flex-col gap-5">
                                    <div className="rounded-xl group-hover:scale-105 duration-100">
                                        <img
                                            data-src={
                                                "https:" +
                                                article.fields.thumbnailImage
                                                    .fields.file.url
                                            }
                                            data-srcset={
                                                article.fields.thumbnailImage
                                                    .fields.file.url
                                            }
                                            data-sizes="auto"
                                            alt={
                                                article.fields.thumbnailImage
                                                    .fields.title
                                            }
                                            className="lazyload rounded-xl"
                                            loading="lazy"
                                            decoding="async"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-1 w-full">
                                        <p className="outfit-medium text-[1rem] xl:text-[1.25rem] text-slate-900 group-hover:text-violet-600 duration-100">
                                            {article.fields.title}
                                        </p>
                                        <p className="outfit text-[1rem] text-slate-500 line-clamp-2">
                                            {article.fields.outline}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </Link>
                    ))}
                </section>
            </div>
        </ReactLenis>
    );
}

export default Project;
