import React, { useEffect, useState } from "react";
import ReactLenis from "lenis/react";
import { useParams } from "react-router-dom";
import client from "../../client";

import FloatingBar from "../../components/floating bar/FloatingBar";
import Markdown from "../../helper/markdown/Markdown";
import AlsoInteristing from "./components/AlsoInteristing";
import BadRequest from "../maintenance/400Page";
import SEO from "../../components/seo/SEO";
import LoadingPage from "../../components/loading/loadingPage";
import PageNotFound from "../maintenance/404Page";

export function withRouter(Children) {
    return (props) => {
        const match = { params: useParams() };
        return <Children {...props} match={match} />;
    };
}

function SingleCode({ match }) {
    const [singleArticles, setSingleArticles] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "uniqueCode",
                    "fields.slug": match?.params.slug,
                });
                setSingleArticles(response.items[0]);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [match?.params.slug]);

    if (isLoading) return <LoadingPage />;

    if (error) return <BadRequest />;

    if (!singleArticles) return <PageNotFound />;

    return (
        <ReactLenis root>
            <SEO
                title={singleArticles.fields.title}
                description={singleArticles.fields.description}
                subject="insight"
                keywords={singleArticles.fields.keywords}
                language={singleArticles.sys.locale}
                revisedDate={singleArticles.sys.updatedAt}
                author="Cahya Putra Ugira"
                url={singleArticles.fields.url}
                newsKeywords={singleArticles.fields.keywords}
            />
            <FloatingBar />
            <div className="flex flex-col gap-20 py-44 w-full xl:w-[1280px] 2xl:w-[1440px] mx-auto">
                <h1
                    data-aos="fade-up"
                    className="outfit-semibold text-slate-900 text-[26px] xs:text-[35px] md:text-[39px] xl:text-[55px] text-center tracking-[0.02rem] w-full lg:w-4/5 mx-auto"
                >
                    {singleArticles.fields.title}
                </h1>
                <div className="flex flex-col lg:flex-row gap-10 xl:gap-20 px-5 lg:px-10 xl:px-16 w-full">
                    <div className="flex flex-col gap-10 w-full lg:w-4/5">
                        <section data-aos="fade-up" className="markdown flex flex-col gap-7 outfit text-[1.1rem] text-slate-700 leading-8">
                            <Markdown>{singleArticles.fields.content}</Markdown>
                        </section>
                        <section className="bg-slate-100 border-l-8 border-black">
                            <div className="flex flex-col gap-5 px-3 md:px-10 py-10">
                                <p className="outfit-semibold text-slate-900">
                                    Summaries
                                </p>
                                <div className="flex flex-col gap-2 outfit text-slate-700 leading-7 md:leading-8">
                                    {singleArticles.fields.summary
                                        .slice(0, 2)
                                        .map((summary, i) => (
                                            <p key={i}>{summary}</p>
                                        ))}
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="sticky top-32 self-start flex flex-col gap-10 w-full lg:w-1/3">
                        <section data-aos="fade-up" className="hidden lg:flex flex-col gap-2">
                            <p className="outfit-medium text-violet-500 capitalize text-[1.25rem] lg:text-[1.1rem]">
                                Tags
                            </p>
                            <div className="flex flex-wrap gap-x-3 gap-y-2 outfit-medium text-[12px] rounded-xl">
                                {singleArticles.fields.tags.map(
                                    (tag, index) => (
                                        <p
                                            key={index}
                                            className="uppercase bg-orange-100 text-black py-1 px-2 rounded-md"
                                        >
                                            {tag}
                                        </p>
                                    )
                                )}
                            </div>
                        </section>
                        <section className="flex flex-col gap-10">
                            {singleArticles.fields.source ? (
                                <div data-aos="fade-up" data-aos-delay="50" className="flex flex-col gap-2">
                                    <p className="outfit-medium text-violet-500 capitalize text-[1.25rem] lg:text-[1.1rem]">
                                        Content Origins
                                    </p>
                                    <div className="flex flex-col gap-5">
                                        {singleArticles.fields.source.map(
                                            (_, i) => (
                                                <a
                                                    href={
                                                        singleArticles.fields
                                                            .source[i].fields
                                                            .sourceLink
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    key={i}
                                                    className="group flex flex-col gap-1 cursor-pointer outline-none w-fit"
                                                >
                                                    <span className="outfit-medium text-[1.1rem] text-slate-900 group-hover:text-violet-500 duration-100">
                                                        {
                                                            singleArticles
                                                                .fields.source[
                                                                i
                                                            ].fields.sourceTitle
                                                        }
                                                    </span>
                                                    <p className="outfit text-gray-500">
                                                        {
                                                            singleArticles
                                                                .fields.source[
                                                                i
                                                            ].fields.sourceName
                                                        }
                                                    </p>
                                                </a>
                                            )
                                        )}
                                    </div>
                                </div>
                            ) : null}
                            <AlsoInteristing
                                alsoInteristing={singleArticles.fields.title}
                            />
                        </section>
                    </div>
                </div>
            </div>
        </ReactLenis>
    );
}

export default withRouter(SingleCode);
