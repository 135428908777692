import React from "react";
import Markdown from "../../../../helper/markdown/Markdown";

const SingleProjectDetails = ({ singleArticles }) => {
    return (
        <>
            <div className="flex flex-col gap-40">
                <section className="markdown flex flex-col gap-7 outfit text-[1rem] lg:text-[1.1rem] text-slate-700 leading-8">
                    <Markdown>{singleArticles.fields.testcase}</Markdown>
                </section>
                {singleArticles.fields.testingCoverage && (
                    <section className="flex flex-col items-center gap-10">
                        <p className="outfit-medium text-violet-500 capitalize text-[1.5rem]">
                            testing coverage
                        </p>
                        <div className="flex flex-wrap justify-center gap-5">
                            {singleArticles.fields.testingCoverage.map(
                                (tc, i) => (
                                    <div
                                        key={i}
                                        data-aos="fade-up"
                                        className="flex flex-col gap-2 w-full xs:w-[325px] bg-fuchsia-100 p-5 rounded-2xl"
                                    >
                                        <p className="outfit-semibold text-[1rem] xl:text-[1.1rem]">
                                            {tc.fields.title}
                                        </p>
                                        <p className="outfit text-gray-600 text-[1rem] lg:text-[1.1rem]">
                                            {tc.fields.description}
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    </section>
                )}
            </div>
        </>
    );
};

export default SingleProjectDetails;
