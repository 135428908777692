import React, { useEffect, useState } from "react";
import client from "../../../client";
import { useParams } from "react-router-dom";
import ReactLenis from "lenis/react";

import FloatingBar from "../../../components/floating bar/FloatingBar";
import BadRequest from "../../maintenance/400Page";
import SEO from "../../../components/seo/SEO";
import SingleProjectHeader from "./singleProjectComponent/SingleProjectHeader";
import SingleProjectComponent from "../singleProjectGlobalComponent/SingleProjectComponent";
import SingleProjectFooter from "../singleProjectGlobalComponent/SingleProjectFooter";
import SingleProjectPublish from "../singleProjectGlobalComponent/SingleProjectPublish";
import LoadingPage from "../../../components/loading/loadingPage";
import PageNotFound from "../../maintenance/404Page";

export function withRouter(Children) {
    return ({ match, ...props }) => {
        const updatedMatch = { params: useParams(), ...match };
        return <Children {...props} match={updatedMatch} />;
    };
}

function SingleProject({ match }) {
    const [singleArticles, setSingleArticles] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await client.getEntries({
                    content_type: "uiux",
                    "fields.slug": match?.params.slug,
                });
                setSingleArticles(response.items[0]);
            } catch (err) {
                setError(err);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [match?.params.slug]);

    if (isLoading) return <LoadingPage />;

    if (error) return <BadRequest />;

    if (!singleArticles) return <PageNotFound />;

    return (
        <>
            <ReactLenis root>
                <SEO
                    subject="project"
                    keywords={singleArticles.fields.keywords}
                    language={singleArticles.sys.locale}
                    revisedDate={singleArticles.sys.updatedAt}
                    author={singleArticles.fields.postedBy}
                    url={singleArticles.fields.url}
                    newsKeywords={singleArticles.fields.keywords}
                />
                <FloatingBar />
                <div className="flex flex-col gap-20 pt-64 pb-32 px-5 lg:px-10 w-full xl:w-[1280px] mx-auto">
                    <SingleProjectHeader singleArticles={singleArticles} />
                    <SingleProjectPublish singleArticles={singleArticles} />
                    <SingleProjectComponent singleArticles={singleArticles} />
                    <SingleProjectFooter singleArticles={singleArticles} />
                    <section className="flex flex-col gap-2 text-center mx-auto outfit lg:text-[1.5rem]">
                        <p>Curious to see more?</p>
                        <p>
                            {" "}
                            Head over to my{" "}
                            <a
                                href="https://dribbble.com/rafaelcahya"
                                target="_blank"
                                className="text-pink-600 underline underline-offset-4"
                            >
                                Dribbble
                            </a>{" "}
                            and dive into my designs, ideas, and creative
                            experiments!
                        </p>
                    </section>
                </div>
            </ReactLenis>
        </>
    );
}

export default withRouter(SingleProject);
