import React from "react";

function SingleWorkHeader({ singleArticles }) {
    return (
        <>
            <div className="flex flex-col gap-5 w-full lg:w-1/2">
                <p data-aos="fade-up" className="outfit-bold text-[2.5rem] md:text-[3.5rem] text-violet-600">
                    {singleArticles.fields.companyName}
                </p>
                <p
                    data-aos="fade-up"
                    className="outfit text-slate-900 text-[1rem] xl:text-[1.1rem] leading-7 xl:leading-8 lg:leading-7"
                >
                    {singleArticles.fields.brief}
                </p>
            </div>
        </>
    );
}

export default SingleWorkHeader;
