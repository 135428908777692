import React from "react";
import { Link } from "react-router-dom";

export default function FloatingBar() {
    return (
        <div className="fixed left-1/2 -translate-x-1/2 top-10 flex gap-4 bg-white/50 backdrop-blur-xl px-6 rounded-full z-20 w-max">
            <Link
                to="/about"
                className=" hover:bg-gray-200 text-gray-700 hover:text-black outfit py-1.5 px-3 my-1.5 rounded-full duration-200"
            >
                Home
            </Link>
            <Link
                to="/project"
                className="hover:bg-gray-200 text-gray-700 hover:text-black outfit py-1.5 px-3 my-1.5 rounded-full duration-200"
            >
                Projects
            </Link>
            <Link
                to="/insight"
                className="hover:bg-gray-200 text-gray-700 hover:text-black outfit py-1.5 px-3 my-1.5 rounded-full duration-200"
            >
                Insights
            </Link>
        </div>
    );
}
