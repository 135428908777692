import React from "react";
import moment from "moment";

function SingleWorkDetails({ singleArticles }) {
    return (
        <>
            <div className="flex flex-col md:flex-row lg:flex-col gap-5 w-full lg:w-1/4">
                <div data-aos="fade-up" className="flex flex-col gap-2">
                    <p className="outfit-medium text-violet-600 uppercase text-[1rem] tracking-wider">
                        Position
                    </p>
                    <p className="outfit text-[1rem] xl:text-[1.05rem] text-slate-900">
                        {singleArticles.fields.position}
                    </p>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-delay="50"
                    className="flex flex-col gap-2"
                >
                    <p className="outfit-medium text-violet-600 uppercase text-[1rem] tracking-wider">
                        Team
                    </p>
                    <p className="outfit text-[1rem] xl:text-[1.05rem] text-slate-900">
                        {singleArticles.fields.team}
                    </p>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className="flex flex-col gap-2"
                >
                    <p className="outfit-medium text-violet-600 uppercase text-[1rem] tracking-wider">
                        Timeline
                    </p>
                    <span className="flex gap-1 outfit text-[1rem] xl:text-[1.05rem] text-slate-900">
                        <p>
                            {moment(singleArticles.fields.startDate).format(
                                "MMM YYYY"
                            )}
                        </p>
                        <p>-</p>
                        <p>
                            {moment(singleArticles.fields.endDate).format(
                                "MMM YYYY"
                            )}
                        </p>
                    </span>
                </div>
            </div>
        </>
    );
}

export default SingleWorkDetails;
